.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: auto;
  height: 38px;
  padding: 0 1rem;
  border: none;
  outline: none;
  background: none;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1rem;
  text-decoration: none;
  text-align: center;
  transition: all 0.3s ease;
  cursor: pointer;

  &--primary {
    color: $white;
    background-color: $primary;

    &:hover {
      background-color: lighten($primary, 10);
    }
  }

  &--secondary {
    color: $black;
    background-color: $secondary;

    &:hover {
      background-color: lighten($secondary, 10);
    }
  }

  &--gray {
    color: $white;
    background-color: $gray;

    &:hover {
      background-color: lighten($gray, 10);
    }
  }

  &--transparent {
    color: $white;
    background-color: transparent;

    &:hover {
      opacity: 0.8;
    }
  }

  &--transparent-primary {
    color: $primary;
    background-color: transparent;

    &:hover {
      opacity: 0.8;
    }
  }

  &--left-addon {
    box-shadow: inset 5px 0 0 0 $white;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      width: 6px;
      height: calc(50% - 6px);
      background-color: $primary;
      transition: background-color 0.3s ease;
    }

    &::before {
      top: 0;
      border-radius: 0 0 0 3px;
    }

    &::after {
      bottom: 0;
      border-radius: 3px 0 0 0;
    }

    &:hover {
      &::before,
      &::after {
        background-color: lighten($primary, 10);
      }
    }
  }

  &--bottom-addon {
    box-shadow: 0 6px 0 0 $secondary;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -6px;
      width: calc(50% - 3px);
      height: 6px;
      background-color: $white;
    }

    &::before {
      left: 0;
      border-radius: 0 6px 0 0;
    }

    &::after {
      right: 0;
      border-radius: 6px 0 0 0;
    }

    &:hover {
      box-shadow: 0 6px 0 0 lighten($secondary, 10);
    }
  }

  &--icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    padding: 0;

    svg {
      display: block;
      width: 18px;
      height: 18px;
    }
  }

  &--static {
    pointer-events: none;
  }

  &:active {
    transform: scale(0.9);
  }

  @media (min-width: 1024px) {
    height: 48px;
    font-size: 1rem;
    padding: 0 2rem;

    &--icon {
      padding: 0;
      width: 48px;

      svg {
        width: 24px;
        height: 24px;
      }

      &--mobile-only {
        width: auto;
        padding: 0 2rem;
      }
    }
  }
}

.menu-button {
  display: block;
  position: relative;
  width: 38px;
  height: 38px;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 1.25rem);
    height: 2px;
    background-color: $black;
    transition: all 0.3s ease;

    &:nth-child(1) {
      top: calc(0.625rem + 2px);
    }

    &:nth-child(2) {
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &:nth-child(3) {
      bottom: calc(0.625rem + 2px);
    }
  }

  &.active {
    span {
      &:nth-child(1) {
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        bottom: 50%;
        transform: translate(-50%, 50%) rotate(-45deg);
      }
    }
  }
}

.scroll-top-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 48px;
  height: 48px;
  border: none;
  outline: none;
  background: none;
  background-color: $primary;
  box-shadow: 0 -10px 0 0 $primary;
  cursor: pointer;
  transition: all 0.3s ease;

  svg {
    width: 24px;
    height: 24px;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: -10px;
    width: calc(50% - 3px);
    height: 10px;
    background-color: $whitesmoke;
    transition: all 0.3s ease;
  }

  &::before {
    left: 0;
    border-radius: 0 0 10px 0;
  }

  &::after {
    right: 0;
    border-radius: 0 0 0 10px;
  }

  &:hover {
    background-color: lighten($primary, 10);
    box-shadow: 0 -10px 0 0 lighten($primary, 10);
  }

  @media (min-width: 1024px) {
    width: 90px;
    height: 90px;

    svg {
      width: 34px;
      height: 44px;
    }
  }
}
