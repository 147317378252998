.article-miniature {
  display: block;
  color: $body;
  text-decoration: none;
  transition: all 0.3s ease;
  cursor: pointer;

  &__image {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 60%;
    overflow: hidden;

    img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__content {
    background-color: $white;
    padding: 20px;

    .badge {
      margin: 0 0 20px -20px;
    }

    h3 {
      margin: 0 0 10px;
      font-size: 1.125rem;
      line-height: 1.5rem;
    }

    p {
      margin: 0 0 20px;
      font-size: 0.875rem;
      line-height: 1.375rem;
    }
  }

  &__footer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    p {
      margin: 0;
      line-height: 1.25;

      span {
        display: block;
        color: $gray;
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    margin: 0 16px 0 0;
    border: 2px solid $primary;

    &::before {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzk5XzIzMTQpIj4KPHBhdGggZD0iTTIwIDEySDEwIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMjAgMTJMMTYgMTYiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0yMCAxMkwxNiA4IiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNNCA0VjIwIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzk5XzIzMTQiPgo8cmVjdCB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 10px rgba($black, 0.1);
  }

  @media (min-width: 1024px) {
    &__content {
      h3 {
        font-size: 1.25rem;
      }

      p {
        font-size: 1rem;
      }
    }

    &__icon {
      width: 48px;
      height: 48px;

      &::before {
        width: 24px;
        height: 24px;
      }
    }

    &--horizontal {
      display: flex;
      flex-flow: row nowrap;
      height: 300px;

      .article-miniature__image {
        width: 487px;
        height: 100%;
        padding-bottom: 0;
      }

      .article-miniature__content {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        height: 100%;
        padding: 20px 45px 25px 30px;

        .badge {
          margin: 0 0 15px -30px;
        }
      }

      .article-miniature__footer {
        margin-top: auto;
      }
    }
  }
}
