.footer {
  position: relative;
  padding: 80px 0 48px;
  background-color: $gray;

  .scroll-top-button {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
  }

  address {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 100%;
    margin: 0 0 32px;
    font-weight: 500;
    color: $white;
    font-style: normal;

    img {
      display: block;
      width: 48px;
      height: auto;
      margin: 0 0 16px;
    }

    p {
      margin: 0;
      width: 100%;
      text-align: center;
    }
  }

  .socials {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: -10px;

    a {
      display: block;
      padding: 10px;

      svg {
        display: block;
        width: 24px;
        height: 24px;
      }

      &:hover {
        opacity: 0.75;
        transition: opacity 0.3s ease;
      }
    }
  }

  .copyright {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 100%;
    margin-top: 32px;
    padding-top: 48px;
    border-top: 1px solid $light;
    font-size: 0.875rem;

    p {
      margin: 0 0 32px;
      color: $white;
    }
  }

  @media (min-width: 1024px) {
    .container {
      flex-flow: row wrap;

      address {
        width: auto;
        flex-flow: row nowrap;
        margin: 0;

        img {
          width: 66px;
          margin: 0;
        }

        p {
          text-align: left;
          margin: 0 0 0 20px;
        }
      }

      .socials {
        width: auto;
      }

      .copyright {
        flex-flow: row wrap;
        justify-content: space-between;

        p {
          margin: 0;
        }
      }
    }
  }
}
