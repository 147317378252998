@import 'base/base';

@import 'components/header';
@import 'components/footer';
@import 'components/navigation';
@import 'components/button';
@import 'components/badge';
@import 'components/title';
@import 'components/article-miniature';
@import 'components/feature';
@import 'components/access-section';

.home-section {
  margin-bottom: 80px;
}

.home-slider {
  height: 550px;

  .slick-list,
  .slick-track,
  .slick-slide > div {
    height: 100%;
  }

  .slide {
    height: 100%;
    padding: 25px 0;
    color: $white;
    background-color: $black;

    &__bg {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      opacity: 0.7;
    }

    .container {
      display: flex;
      position: relative;
      z-index: 1;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-end;
      height: 100%;
    }

    .badge {
      margin: 0 0 25px;
    }

    h1 {
      max-width: 965px;
      margin: 0 0 20px;
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 2.25rem;
    }

    p {
      max-width: 730px;
      margin: 0 0 30px;
      font-size: 0.875rem;
    }

    &__buttons {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      margin: -10px;

      .button {
        margin: 10px;
      }
    }
  }
}

.incoming-events-section,
.newses-section {
  padding: 80px 0;

  .container {
    display: flex;
    position: relative;
    flex-flow: column nowrap;
    align-items: flex-start;
  }

  .title {
    position: absolute;
    top: -80px;
    left: 15px;
    max-width: calc(100% - 30px);
    transform: translateY(-50%);
  }

  .buttons {
    width: 100%;
    text-align: center;
    padding-top: 54px;
  }
}

.incoming-events-section {
  background-color: $secondary;
}

.newses-section {
  background-color: rgba($black, 0.25);
}

.features-section {
  padding: 80px 0;

  .container {
    .features-image {
      display: none;
      width: 100%;

      > img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    .features-block {
      text-align: center;

      h2 {
        font-size: 1.25rem;
        line-height: 1.75rem;
        margin: 0 0 32px;
      }

      .features {
        margin: -20px;
      }

      .button {
        margin-top: 32px;
      }
    }
  }
}

.attractions-section {
  padding: 80px 0;

  h2 {
    margin: 0 0 32px;
    font-size: 1.25rem;
    line-height: 1.75rem;
    text-align: center;
  }

  .attractions-grid {
    margin: -15px;

    a {
      display: block;
      padding: 15px;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
}

@media (min-width: 1024px) {
  .home-section {
    margin-bottom: 138px;
  }

  .home-slider {
    height: 800px;

    .slide {
      padding: 100px 0;

      h1 {
        font-size: 3rem;
        line-height: 3.75rem;
      }

      p {
        font-size: 1rem;
      }
    }
  }

  .incoming-events-section,
  .newses-section {
    padding: 125px 0 76px;

    .title {
      top: -125px;
    }
  }

  .features-section {
    padding: 110px 0 164px;

    .container {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      .features-image {
        display: block;
        width: 50%;
        padding-right: 40px;
      }

      .features-block {
        width: 50%;
        padding-left: 40px;
        text-align: left;

        h2 {
          font-size: 2.5rem;
          line-height: 2.75rem;
        }

        .features {
          display: flex;
          flex-flow: row wrap;
          margin: 0 -15px;
          width: calc(100% + 30px);

          .feature {
            width: 50%;
            padding: 32px 15px;
          }
        }
      }
    }
  }

  .attractions-section {
    padding: 110px 0 130px;

    h2 {
      margin-bottom: 50px;
      font-size: 2.5rem;
      line-height: 2.75rem;
    }

    .attractions-grid {
      display: flex;
      flex-flow: row wrap;

      a {
        width: 50%;
      }
    }
  }
}
