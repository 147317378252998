.feature {
  padding: 20px;
  text-align: center;

  &__icon {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    margin: 0 auto 22px;
    background-color: $primary;
    box-shadow: 0 6px 0 0 $primary;

    svg {
      width: 30px;
      height: 30px;
    }

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -6px;
      width: calc(50% - 3px);
      height: 6px;
      background-color: $whitesmoke;
    }

    &::before {
      left: 0;
      border-radius: 0 6px 0 0;
    }

    &::after {
      right: 0;
      border-radius: 6px 0 0 0;
    }
  }

  p {
    margin: 0;
  }

  @media (min-width: 1024px) {
    padding: 15px;
    font-size: 1.125rem;
    text-align: left;

    &__icon {
      margin: 0 0 22px;
    }
  }
}
