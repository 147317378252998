.navigation {
  &__container {
    position: absolute;
    z-index: 100;
    top: 78px;
    right: 10px;
    transform: scale(0);
    opacity: 0;
    transform-origin: calc(100% - 20px) top;
    transition: all 0.3s ease;

    &::before {
      content: '';
      display: block;
      position: absolute;
      z-index: 0;
      top: -5px;
      right: 15px;
      width: 10px;
      height: 10px;
      background-color: $white;
      transform: rotate(45deg);
      box-shadow: 0 0 5px 0 rgba($black, 0.1);
      transition: all 0.3s ease;
    }

    ul {
      display: flex;
      position: relative;
      z-index: 1;
      flex-flow: column nowrap;
      min-width: 240px;
      margin: 0;
      padding: 20px 0;
      list-style: none;
      background-color: $white;

      li {
        a {
          display: block;
          padding: 10px 30px;
          color: $body;
          font-weight: 700;
          text-decoration: none;
        }
      }
    }
  }

  .backdrop {
    display: none;
  }

  &.active {
    .navigation__container {
      transform: scale(1);
      opacity: 1;
      box-shadow: 0 0 10px 0 rgba($black, 0.1);
    }

    .backdrop {
      display: block;
    }
  }

  @media (min-width: 1024px) {
    &__container {
      position: static;
      transform: scale(1);
      opacity: 1;

      ul {
        display: flex;
        flex-flow: row nowrap;
        min-width: 0;
        margin: 0 -12px;
        padding: 0;
        background-color: transparent;
        box-shadow: none;

        li {
          a {
            padding: 12px;
          }
        }
      }
    }
  }
}
