.badge {
  display: inline-block;
  position: relative;
  padding: 0.25rem calc(0.75rem - 5px) 0.25rem 0.75rem;
  background-color: $secondary;
  color: $white;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.5rem;
  text-transform: uppercase;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 100%;
    width: 5px;
    height: calc(50% - 5px);
    background-color: $secondary;
  }

  &::before {
    top: 0;
    border-radius: 0 0 2px 0;
  }

  &::after {
    bottom: 0;
    border-radius: 0 2px 0 0;
  }

  @media (min-width: 1024px) {
    font-size: 1rem;
    padding: 0.5rem calc(1rem - 5px) 0.5rem 1rem;
  }
}
