@import '~node_modules/normalize.css/normalize.css';
@import '~node_modules/slick-carousel/slick/slick.css';
@import '~node_modules/slick-carousel/slick/slick-theme.css';
@import '~node_modules/simplelightbox/dist/simple-lightbox.min.css';
@import 'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap';

@import 'colors';

*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  background-color: $whitesmoke;
  color: $body;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
}

body,
main {
  overflow-x: hidden;
}

.container {
  padding: 0 15px;
  max-width: 1550px;
  margin: auto;
}

.sr-only-mobile {
  @media (max-width: 1023px) {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    margin: -1px !important;
    padding: 0 !important;
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    clip-path: inset(50%) !important;
    white-space: nowrap !important;
    overflow: hidden !important;
  }
}

.hidden-desktop {
  @media (min-width: 1024px) {
    display: none !important;
  }
}

.backdrop {
  position: fixed;
  z-index: 90;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slider {
  width: calc(100% + 30px);
  margin: 0 -15px;

  .slick-slide {
    padding: 0 15px;
  }

  .slick-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    background-color: $white;
    transition: all 0.3s ease;

    &::before {
      content: '';
      display: block;
      width: 25px;
      height: 20px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &:active {
      transform: translate(0, -50%) scale(0.9);
    }
  }

  .slick-prev {
    left: -86px;

    &::before {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyNiAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI1LjU2NTcgNy4yMzQ1NkgxMC44ODY0QzEyLjI3NCA1LjIwNDgyIDEzLjA4MzkgMi43NTU1OSAxMy4wODM5IDAuMTE1OTY3SDcuNTU2MjFDNy41NTYyMSA0LjAzOTkgNC4zNjQ3OSA3LjIzNDU2IDAuNDM3NjIyIDcuMjM0NTZWMTIuNzYyM0M0LjM2MTU2IDEyLjc2MjMgNy41NTYyMSAxNS45NTM3IDcuNTU2MjEgMTkuODgwOUgxMy4wODM5QzEzLjA4MzkgMTcuMjQ0NSAxMi4yNzA3IDE0Ljc5MiAxMC44ODMyIDEyLjc2MjNIMjUuNTY1N1Y3LjIzNDU2WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==);
    }
  }

  .slick-next {
    right: -86px;
    &::before {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyNiAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuNDM0MzQgMTIuNzY1NEwxNS4xMTM2IDEyLjc2NTRDMTMuNzI2IDE0Ljc5NTIgMTIuOTE2MSAxNy4yNDQ0IDEyLjkxNjEgMTkuODg0TDE4LjQ0MzggMTkuODg0QzE4LjQ0MzggMTUuOTYwMSAyMS42MzUyIDEyLjc2NTQgMjUuNTYyNCAxMi43NjU0TDI1LjU2MjQgNy4yMzc3M0MyMS42Mzg0IDcuMjM3NzIgMTguNDQzOCA0LjA0NjMgMTguNDQzOCAwLjExOTEzNkwxMi45MTYxIDAuMTE5MTM2QzEyLjkxNjEgMi43NTU1MyAxMy43MjkzIDUuMjA3OTkgMTUuMTE2OCA3LjIzNzcyTDAuNDM0MzQxIDcuMjM3NzJMMC40MzQzNCAxMi43NjU0WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==);
    }
  }
}

.sl-overlay {
  background-color: rgba($black, 0.75);
  backdrop-filter: blur(10px);
  opacity: 1 !important;
}

.sl-wrapper {
  .sl-close {
    background-color: $white;
    line-height: 1;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.75 !important;
    }
  }

  .sl-navigation {
    button {
      &.sl-prev,
      &.sl-next {
        background-color: $white;
        overflow: hidden;
        color: $white;
        font-size: 0;
        line-height: 0;
        background-repeat: no-repeat;
        background-position: center;
        transition: opacity 0.3s ease;
      }

      &.sl-prev {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyNiAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI1LjU2NTcgNy4yMzQ1NkgxMC44ODY0QzEyLjI3NCA1LjIwNDgyIDEzLjA4MzkgMi43NTU1OSAxMy4wODM5IDAuMTE1OTY3SDcuNTU2MjFDNy41NTYyMSA0LjAzOTkgNC4zNjQ3OSA3LjIzNDU2IDAuNDM3NjIyIDcuMjM0NTZWMTIuNzYyM0M0LjM2MTU2IDEyLjc2MjMgNy41NTYyMSAxNS45NTM3IDcuNTU2MjEgMTkuODgwOUgxMy4wODM5QzEzLjA4MzkgMTcuMjQ0NSAxMi4yNzA3IDE0Ljc5MiAxMC44ODMyIDEyLjc2MjNIMjUuNTY1N1Y3LjIzNDU2WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==);
      }

      &.sl-next {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyNiAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuNDM0MzQgMTIuNzY1NEwxNS4xMTM2IDEyLjc2NTRDMTMuNzI2IDE0Ljc5NTIgMTIuOTE2MSAxNy4yNDQ0IDEyLjkxNjEgMTkuODg0TDE4LjQ0MzggMTkuODg0QzE4LjQ0MzggMTUuOTYwMSAyMS42MzUyIDEyLjc2NTQgMjUuNTYyNCAxMi43NjU0TDI1LjU2MjQgNy4yMzc3M0MyMS42Mzg0IDcuMjM3NzIgMTguNDQzOCA0LjA0NjMgMTguNDQzOCAwLjExOTEzNkwxMi45MTYxIDAuMTE5MTM2QzEyLjkxNjEgMi43NTU1MyAxMy43MjkzIDUuMjA3OTkgMTUuMTE2OCA3LjIzNzcyTDAuNDM0MzQxIDcuMjM3NzJMMC40MzQzNCAxMi43NjU0WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==);
      }
    }
  }
}
