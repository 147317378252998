.header {
  display: flex;
  position: relative;
  z-index: 95;
  flex-flow: row nowrap;
  align-items: center;
  height: 68px;
  padding: 0 10px;
  background-color: $white;
  box-shadow: 0 3px 10px 0 rgba($black, 0.1);

  .logo {
    display: block;
    height: 48px;
    margin: 0 auto 0 0;

    img {
      width: auto;
      height: 100%;
    }

    &__desktop {
      display: none;
    }

    &__mobile {
      display: block;
    }
  }

  .toolbar {
    display: flex;
    flex-flow: row nowrap;
    margin: 0 -3px;

    .button,
    .menu-button {
      margin: 0 3px;
    }
  }
}

@media (min-width: 1024px) {
  .header {
    height: 100px;
    padding: 0 50px;
    justify-content: space-between;

    .logo {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 188px;
      margin: 0;
      padding: 28px;

      img {
        position: relative;
        z-index: 2;
      }

      &__desktop {
        display: block;
      }

      &__mobile {
        display: none;
      }

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
        background-color: $white;
      }

      &::before {
        z-index: 1;
        top: 0;
        height: 100%;
      }

      &::after {
        z-index: 0;
        bottom: 0;
        left: 0;
        height: 88px;
        box-shadow: 0 3px 10px 0 rgba($black, 0.1);
      }
    }

    .toolbar {
      margin: 0 -10px;

      .button,
      .menu-button {
        margin: 0 10px;
      }

      .menu-button {
        display: none;
      }
    }
  }
}
