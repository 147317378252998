.title {
  position: relative;
  background-color: $white;
  margin: 0;
  padding: 15px 25px;
  font-size: 1.25rem;
  line-height: 1.75rem;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: 35px;
    width: 33px;
    height: 13px;
    margin-top: -1px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDMzIDEzIiB3aWR0aD0iMzMiIGhlaWdodD0iMTMiPjxzdHlsZT4uYXtmaWxsOiNmZmZ9PC9zdHlsZT48cGF0aCBjbGFzcz0iYSIgZD0ibTEwLjggMTNoMTEuOGMwLTYuOSA1LjYtMTIuNiAxMi42LTEyLjZoODAyLjh2LTEwNS40aC04NzR2MTA1LjRoMzQuMmM3IDAgMTIuNiA1LjcgMTIuNiAxMi42eiIvPjwvc3ZnPg==);
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
  }

  &--lg {
    padding: 20px 30px;
    font-size: 1.5rem;
    line-height: 1.875rem;
  }

  @media (min-width: 1024px) {
    padding: 30px 45px;
    font-size: 2.5rem;
    line-height: 3rem;

    &--lg {
      padding: 45px 60px;
      font-size: 3.125rem;
      line-height: 3.5rem;
    }
  }
}
