.access-section {
  padding: 0 0 80px;

  .access-description {
    h2 {
      margin: 0 0 32px;
      font-size: 1.25rem;
      line-height: 1.75rem;
      text-align: center;
    }

    .access-options {
      margin: -20px;
    }

    .buttons {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      margin: 22px -10px -10px;

      .button {
        margin: 10px;
      }
    }
  }

  .map-image {
    display: block;
    width: 100%;
    padding: 32px 0 0;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  @media (min-width: 1024px) {
    padding: 0 0 147px;

    .container {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
    }

    .access-description {
      width: 50%;
      padding-right: 40px;

      h2 {
        margin: 0 0 64px;
        font-size: 2.5rem;
        line-height: 2.75rem;
        text-align: left;
      }

      .access-options {
        display: flex;
        flex-flow: row wrap;
        margin: -15px;

        .feature {
          width: 50%;
        }
      }

      .buttons {
        margin: 54px -10px -10px;
      }
    }

    .map-image {
      width: 50%;
      padding: 0 0 0 40px;
    }
  }
}
